import React from "react"

const DisclaimerComponent = () => {
  const links = {
    "cookie-policy":
      "https://insuranceoctopus.co.uk/cookie-policy/?utm_source=bing&utm_medium=cpc&utm_term=professional%2Bindemnity&utm_campaign=GPPCPI&gclid=CLz3gPDd4e0CFQ7hGwodggoB1A",
    "privacy-policy":
      "https://insuranceoctopus.co.uk/privacy-policy/?utm_source=bing&utm_medium=cpc&utm_term=professional%2Bindemnity&utm_campaign=GPPCPI&gclid=CLz3gPDd4e0CFQ7hGwodggoB1A",
    imprint: "/",
    "contact-mail": "kontakt@bolttech.at",
    "trade-authority":
      "https://www.bmaw.gv.at/Themen/Unternehmen/Versicherungsvermittlung.html",
    ris: "www.ris.bka.gv.at",
  }
  return (
    <div className="tnc-section bg-white">
      <section className="container tnc-wrapper">
        <h3 className="bold title">Impressum</h3>
        <p>
          Informationspflicht gemäß § 5 E-Commerce Gesetz (ECG), § 14
          Unternehmensgesetzbuch (UGB), § 63 Gewerbeordnung (GewO) und der §§
          24, 25 Mediengesetz (MedienG).
        </p>
        <p>
          <strong>Firmenwortlaut</strong>: BOLTTECH DIGITAL INSURANCE AGENCY
          (EU) GmbH.
        </p>
        <p>
          <strong>Rechtsform</strong>: Gesellschaft mit beschränkter Haftung
        </p>
        <p>
          <strong>Sitz der Gesellschaft</strong>: Kohlmarkt 8-10, 1010 Wien
          Österreich
        </p>
        <p>
          <strong>Telefon</strong>: +43 1 227461075
        </p>
        <p>
          <strong> E-Mail: </strong>
          <a href={"mailto:"+links["contact-mail"]}>{links["contact-mail"]}</a>
        </p>
        <p>
          <strong>Unternehmensgegenstand:</strong> Gegenstand des Unternehmens
          ist die Ausübung des Berufs des Versicherungsvertreters und aller
          damit zusammenhängenden Tätigkeiten.
        </p>
        <p>
          <strong>GISA-Nummer:</strong> GISA 32620474
        </p>
        <p>
          <strong>GLN:</strong> 9L10028674730
        </p>

        <p>
          <strong>Firmenbuchnummer:</strong> FN 531434x
        </p>

        <p>
          <strong>Behörde:</strong> Magistrat der Stadt Wien
        </p>

        <p>
          <strong>Aufsichtsbehörde/Gewerbebehörde:</strong> Bundesministerium
          für Digitalisierung und Wirtschaftsstandort, Website unter: <br />
          <br />
          <a href={links["trade-authority"]} target="_blank">
            {links["trade-authority"]}
          </a>
        </p>
        <p>
          <strong>Kammerzugehörigkeit:</strong> Wirtschaftskammer Wien
        </p>
        <p>
          <strong>Berufsrecht:</strong>  Gewerbeordnung 1994, abrufbar unter:
          <a href={"https://"+links.ris} target="_blank">
             {links.ris}
          </a>
        </p>
        <p>
          <strong>Verleihungsstaat:</strong> Österreich
        </p>
        <p>
          <strong> Geschäftsführer:</strong> Lorenzo Stipulate
        </p>
        <p>
          <strong>Gesellschafter:</strong> BOLTTECH HOLDINGS (EUROPE) SARL,
          Luxemburg
        </p>
        <p>
          <strong>Blattlinie:</strong> Diese Website dient der Bewerbung der von
          AIG Europe S.A. angebotenen Produkte und Dienstleistungen durch die
          BOLTTECH DIGITAL INSURANCE AGENCY (EU) GmbH
        </p>
      </section>
    </div>
  )
}
export default DisclaimerComponent
